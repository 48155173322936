*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: 'Inter', serif;
  font-size: 20px;
  color: #191919;
  /*min-height: 100vh;*/
  /*min-width: 100vh;*/
}

p {
  line-height: 1.5;
}

.container {

}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 30px); }
  100%   { transform: translate(0, -0px); }
}

.wrapper {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1400px;
}

.flex-container {
  padding-top: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
}

.flex-container-footer {
  margin-top: 100px;
  background-color: #3C647D;
  color: gainsboro;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.flex-container1 {
  display: flex;
  gap: 30px;
  align-items: center;
}

.flex-item1 {
  flex-grow: 4;
  position: relative;
}
.flex-item2 {
  flex-grow: 1;
}

.flex-item1-video {
  flex: 1;
}

.flex-item2-video {
  flex: 1;
}

.pt-10 {
  padding-top: 30px;
}


.row {
  width: auto;
}

h1 {
  font-size: 6rem;
  font-weight: bolder;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.mainlogo {
  max-width: 1400px;
}

.logo {
  max-width: 900px;
}

.buylogo {
  min-width: 200px;
}

.text-center {
  text-align: center;
}

.flex-item {
  min-width: 100%;
}

.footer {
  padding: 30px;
}

.diceline {
  max-height: 325px;
}

.logo-half {
  display: none;
}

.finalDetails-mobile {
  display: none;
}

.video-width {
  max-width: 550px;
}

@media only screen and (max-width: 1380px) {

  h1 {
    font-size: 2.8rem;
    font-weight: bolder;
  }

  body {
    width: 100%;
    padding: 0;
  }

  .mainlogo {
    max-width: 100%;
  }

  .logo-half {
    display: block;
  }

  .finalDetails-mobile {
    display: block;
  }

  .logo-item .logo {
    display: none;
  }

  .logo-item .logo-half {
    margin-left: -30px;
    opacity: 0.2;
    /*text-indent: -50%;*/
    /*left: -10px;*/
  }

  .video-width {
    max-width: 100%;
  }

  .diceline {
    max-height: 225px;
  }

  .finalDetails {
    display: none;
  }

  .diceline-mobile {
    max-width:100%;
  }

  .flex-container1.no-flex-mobile {
    display: block;
  }

  .flex-container1.kill-gap {
    gap: 0;
  }

  .pt-10-m {
    padding-top: 30px;
  }

  .floating {
    text-align: center;
  }
}
